import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Inject,
} from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';
import { LibIconsRegistryService } from '@common/services';
import { completeIconSet } from '../assets/app-icon.model';
import { DateTime } from 'luxon';
import { DOCUMENT_HIDDEN_SESSION_EXPIRE } from '@common/config';
import { NotificationWrapperComponent } from '@common/components';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, RouterOutlet, NotificationWrapperComponent],
})
export class AppComponent {
  @HostListener('document:visibilitychange')
  public visibilityChange() {
    if (this.document.hidden) {
      sessionStorage.setItem('hiddenStart', DateTime.now().toISO());
    } else {
      const hiddenStart = sessionStorage.getItem('hiddenStart');
      sessionStorage.removeItem('hiddenStart');
      if (!hiddenStart) {
        return;
      }
      const { minutes } = DateTime.now().diff(
        DateTime.fromISO(hiddenStart),
        'minutes',
      );
      if (minutes <= DOCUMENT_HIDDEN_SESSION_EXPIRE) {
        return;
      }
      localStorage.clear();
      void this.router.navigate(['/', 'auth']);
    }
  }

  constructor(
    private readonly libIconsRegistryService: LibIconsRegistryService,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly router: Router,
  ) {
    libIconsRegistryService.registerIcons(completeIconSet);
    sessionStorage.clear();
  }
}
