import { createEnvelope } from '@sentry/utils';

/**
 * Create envelope from Span item.
 */
function createSpanEnvelope(spans) {
  const headers = {
    sent_at: new Date().toISOString()
  };
  const items = spans.map(createSpanItem);
  return createEnvelope(headers, items);
}
function createSpanItem(span) {
  const spanHeaders = {
    type: 'span'
  };
  return [spanHeaders, span];
}
export { createSpanEnvelope };
