import { AuthEffects } from '../auth.page/store';
import { CouriersEffects } from '../cabinet.page/couriers.page/store';
import { PaymentsEffects } from '../cabinet.page/payments.page/store';
import { RegistriesEffects } from '../cabinet.page/registries.page/store';
import { CabinetEffects } from '../cabinet.page/store';

export const effects = [
  CabinetEffects,
  AuthEffects,
  CouriersEffects,
  // PaymentsEffects,
  RegistriesEffects,
];
