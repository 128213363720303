import {
  HttpEvent,
  HttpHandlerFn,
  HttpHeaders,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environment/environment';
import { SET_HEADERS_EXCLUDE_PATHS } from '@common/config';

export function headersInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  const isExclude = SET_HEADERS_EXCLUDE_PATHS.some((url: string) =>
    url.includes(req.url),
  );
  const token = localStorage.getItem('accessToken');
  if (isExclude || !token) {
    return next(req);
  }
  const headers: Record<string, string> = {};
  req.headers
    .keys()
    .forEach((key: string) => (headers[key] = req.headers.get(key) as string));
  return next(
    req.clone({
      headers: new HttpHeaders({
        ...headers,
        Authorization: `Bearer ${token}`,
      }),
    }),
  );
}
